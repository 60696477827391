import { STORE_STATUS } from 'common/constants';
import { DateTime, Interval } from 'luxon';
import { equals } from 'ramda';

import { getFullDaySchedulingInterval } from '../../locationsModule/screens/EstimatedTimePage/utils';
import { normalizeFulfillmentMode } from '../../../common/utils';
import { IsOrderWithinOpeningHoursType } from './types';

export const isTimeWithinOpeningHours: IsOrderWithinOpeningHoursType = (estimatedDateTime, openingHours) => {
  const dayName = estimatedDateTime.weekdayShort.toLowerCase();
  const yesterdayName = estimatedDateTime.minus({ day: 1 }).weekdayShort.toLowerCase();
  if (openingHours) {
    const { offDay, closeAt, openAt } = openingHours[dayName];
    const { offDay: yesterdayOffDay, closeAt: yesterdayCloseAt, openAt: yesterdayOpenAt } = openingHours[yesterdayName];
    const fullDayInterval = !offDay && getFullDaySchedulingInterval(estimatedDateTime, openAt, closeAt);
    const yesterdayFullDayInterval =
      !yesterdayOffDay && getFullDaySchedulingInterval(estimatedDateTime.minus({ days: 1 }), yesterdayOpenAt, yesterdayCloseAt);
    const result =
      (Interval.isInterval(fullDayInterval) && fullDayInterval.contains(estimatedDateTime)) ||
      (Interval.isInterval(yesterdayFullDayInterval) && yesterdayFullDayInterval.contains(estimatedDateTime));
    return result;
  }

  return false;
};

const getBusyHoursInterval = (currentMode, busyMode, busyFrom, busyUntil) => {
  if (busyMode !== 'both' && normalizeFulfillmentMode(currentMode) !== busyMode) return false;
  if (!busyUntil) return false;

  const busyInterval = Interval.fromDateTimes(
    busyFrom ? DateTime.fromISO(busyFrom) : DateTime.now(),
    DateTime.fromISO(busyUntil),
  );
  return busyInterval;
};

export const isBranchBusyAtDateTime = (datetime = DateTime.now(), currentMode, busyMode, busyFrom, busyUntil) => {
  const busyInterval = getBusyHoursInterval(currentMode, busyMode, busyFrom, busyUntil);
  if (!busyInterval) return false;
  return busyInterval.contains(datetime);
};

export const isBranchBusyAtSlot = (interval, currentMode, busyMode, busyFrom, busyUntil) => {
  const busyInterval = getBusyHoursInterval(currentMode, busyMode, busyFrom, busyUntil);
  if (!busyInterval) return false;
  return busyInterval.overlaps(interval);
};

export const isAsapAvailable = (isBusy, branchBusyUntil, openingHours, estimatedTimeInMinutes, maxFulfillmentTime) => {
  if (estimatedTimeInMinutes >= maxFulfillmentTime) {
    return {
      isASAPAvailable: false,
      reason: 'exceededFulfilmentTime',
    };
  }

  const orderEstimatedDateTime = DateTime.now().plus({ minute: estimatedTimeInMinutes || 0 });
  const isOrderSubmissionWithinOpeningHours = isTimeWithinOpeningHours(DateTime.now(), openingHours);
  const isOrderFulfillmentWithinOpeningHours = isTimeWithinOpeningHours(orderEstimatedDateTime, openingHours);
  if (!(isOrderSubmissionWithinOpeningHours && isOrderFulfillmentWithinOpeningHours)) {
    return {
      isASAPAvailable: false,
      reason: STORE_STATUS.closed,
    };
  }

  if (isBusy) {
    const busyUntilDateTime = DateTime.fromISO(branchBusyUntil);
    if (isTimeWithinOpeningHours(busyUntilDateTime, openingHours)) {
      return {
        isASAPAvailable: false,
        reason: STORE_STATUS.busy,
        openingDateTime: busyUntilDateTime,
      };
    }
    return {
      isASAPAvailable: false,
      reason: STORE_STATUS.closed,
    };
  }

  return { isASAPAvailable: true };
};

export const compareDeliveryZoneInCartCacheResponse = (persistedDeliveryZone, supportedDeliveryZone, cartDeliveryZone) => {
  const comparablePersistedSupportedDeliveryZoneObject = {
    branchId: persistedDeliveryZone?.branchReferenceId,
    countryId: persistedDeliveryZone?.countryId,
    deliveryFee: persistedDeliveryZone?.deliveryFee,
    deliveryTime: persistedDeliveryZone?.deliveryTime,
    id: persistedDeliveryZone?.id,
    minimumOrder: persistedDeliveryZone?.minimumOrder,
    openingHours: persistedDeliveryZone?.openingHours,
    restaurantId: persistedDeliveryZone?.restaurantReferenceId,
    zoneName: persistedDeliveryZone?.zoneName,
  };
  const comparableSupportedDeliveryZoneObject = {
    branchId: supportedDeliveryZone?.branchReferenceId,
    countryId: supportedDeliveryZone?.countryId,
    deliveryFee: supportedDeliveryZone?.deliveryFee,
    deliveryTime: supportedDeliveryZone?.deliveryTime,
    id: supportedDeliveryZone?.id,
    minimumOrder: supportedDeliveryZone?.minimumOrder,
    openingHours: supportedDeliveryZone?.openingHours,
    restaurantId: supportedDeliveryZone?.restaurantReferenceId,
    zoneName: supportedDeliveryZone?.zoneName,
  };
  const comparableCartDeliveryZoneObject = {
    branchId: parseInt(cartDeliveryZone?.branch_id) || cartDeliveryZone?.branchId,
    countryId: cartDeliveryZone?.country_id || cartDeliveryZone?.countryId || null,
    deliveryFee: !Number.isNaN(cartDeliveryZone?.delivery_fee) ? cartDeliveryZone?.delivery_fee : cartDeliveryZone?.deliveryFee,
    deliveryTime: !Number.isNaN(cartDeliveryZone?.delivery_time)
      ? cartDeliveryZone?.delivery_time
      : cartDeliveryZone?.deliveryTime,
    id: cartDeliveryZone?.id,
    minimumOrder: !Number.isNaN(cartDeliveryZone?.minimum_order)
      ? cartDeliveryZone?.minimum_order
      : cartDeliveryZone?.minimumOrder,
    openingHours: cartDeliveryZone?.opening_hours || cartDeliveryZone?.openingHours,
    restaurantId: parseInt(cartDeliveryZone?.restaurant_id) || cartDeliveryZone?.restaurantReferenceId,
    zoneName: cartDeliveryZone?.zone_name || cartDeliveryZone?.zoneName,
  };

  return (
    equals(comparablePersistedSupportedDeliveryZoneObject, comparableSupportedDeliveryZoneObject) &&
    equals(comparableCartDeliveryZoneObject, comparableSupportedDeliveryZoneObject)
  );
};
