import { DateTime, Duration, Interval } from 'luxon';
import { EstimatedTimeType, getDurationTimeTextType, SchedulingTimesType } from './types';
import { FULFILLMENT_MODES, ORDER_TIME_TYPES } from '../../../../common/constants';
import { capitalizeTxt, logger } from '../../../../common/utils';

const getWeekDayLongName = currentDay => {
  const today = DateTime.now();
  const tomorrow = today.plus({ days: 1 });
  if (today.day === currentDay.day) return capitalizeTxt(currentDay.toRelativeCalendar());
  if (tomorrow.day === currentDay.day && tomorrow.month === today.month) return capitalizeTxt(currentDay.toRelativeCalendar());
  return capitalizeTxt(currentDay.weekdayLong);
};

const isWorkingDay = (weekDayDateTime, workingHoursInDays) => {
  if (workingHoursInDays) {
    return !workingHoursInDays[weekDayDateTime.setLocale('en').weekdayShort.toLowerCase()]?.offDay;
  }
  return false;
};

/**
 *
 * @param openingHours
 * @param defaultEstimatedTime
 * @param scheduleInterval
 * @param scheduleMaxTimePeriod
 * @param isBranchBusyAtSlot
 * @return {SchedulingTimesType}
 */

export const getWorkingDaysWithSchedulingSlots = (
  openingHours,
  defaultEstimatedTime,
  scheduleInterval,
  scheduleMaxTimePeriod,
  isBranchBusyAtSlot,
) => {
  const schedulingWeekDaysNumber = getMaxSchedulingDays(scheduleMaxTimePeriod);
  const result = [];

  const today = DateTime.now();
  const maxDateTimeToScheduleOrder = today.plus({ hours: scheduleMaxTimePeriod });

  let prevRemainingSlotsForNextDay = [];
  for (let i = 0; i < schedulingWeekDaysNumber; i++) {
    const weekDayDateTimeEn = today.plus({ days: i });
    const weekDayDateTimeAr = configureToArDate(weekDayDateTimeEn);
    if (isWorkingDay(weekDayDateTimeEn, openingHours)) {
      const { todaySchedulingSlots, remainingSlotsForNextDay } = getSchedulingSlotsOfTheDay(
        weekDayDateTimeEn,
        openingHours[weekDayDateTimeEn.setLocale('en').weekdayShort.toLowerCase()]?.openAt,
        openingHours[weekDayDateTimeEn.setLocale('en').weekdayShort.toLowerCase()]?.closeAt,
        scheduleInterval,
        defaultEstimatedTime,
        maxDateTimeToScheduleOrder,
        isBranchBusyAtSlot,
      );
      if (todaySchedulingSlots.length) {
        result.push({
          dayLongName: {
            en: getWeekDayLongName(weekDayDateTimeEn),
            ar: getWeekDayLongName(weekDayDateTimeAr),
          },
          dateText: {
            en: weekDayDateTimeEn.toLocaleString({ month: 'short', day: '2-digit' }),
            ar: weekDayDateTimeAr.toLocaleString({ month: 'short', day: '2-digit' }),
          },
          date: weekDayDateTimeEn,
          schedulingSlots: [...prevRemainingSlotsForNextDay, ...todaySchedulingSlots],
        });
      }
      prevRemainingSlotsForNextDay = remainingSlotsForNextDay;
    } else if (prevRemainingSlotsForNextDay && prevRemainingSlotsForNextDay.length) {
      result.push({
        dayLongName: getWeekDayLongName(weekDayDateTimeEn),
        dateText: weekDayDateTimeEn.toLocaleString({ month: 'short', day: '2-digit' }),
        date: weekDayDateTimeEn,
        schedulingSlots: [...prevRemainingSlotsForNextDay],
      });
      prevRemainingSlotsForNextDay = [];
    }
  }
  return result;
};

/**
 *
 * @param dateTime:DateTime
 * @param opensAt:string
 * @param closeAt
 * @param scheduleInterval
 * @param defaultEstimatedTime
 * @param maxDateTimeToScheduleOrder
 * @param isBranchBusyAtSlot
 * @return {Object}
 *  todaySchedulingSlots {Array<SchedulingSlotType>},
 *  remainingSlotsForNextDay {Array<SchedulingSlotType>} array of scheduling slots of the slots after 12AM,
 */

export const getFullDaySchedulingInterval = (dateTime, opensAt, closeAt) => {
  const [openAtHours, openAtMinutes] = opensAt?.split(':') || [];
  const [closeAtHours, closeAtMinutes] = closeAt?.split(':') || [];

  const openDateTime = dateTime.set({ hour: openAtHours, minute: openAtMinutes, second: 0, millisecond: 0 });
  let closeDateTime = dateTime.set({ hour: closeAtHours, minute: closeAtMinutes, second: 0, millisecond: 0 });

  if (closeDateTime <= openDateTime) closeDateTime = closeDateTime.plus({ days: 1 });

  const fullDaySchedulingInterval = Interval.fromDateTimes(openDateTime, closeDateTime);
  return fullDaySchedulingInterval;
};

export const getSchedulingSlotsOfTheDay = (
  dateTime,
  opensAt,
  closeAt,
  scheduleInterval,
  defaultEstimatedTime,
  maxDateTimeToScheduleOrder,
  isBranchBusyAtSlot,
) => {
  const fullDaySchedulingInterval = getFullDaySchedulingInterval(dateTime, opensAt, closeAt);
  let schedulingSlots = fullDaySchedulingInterval.splitBy({ minutes: scheduleInterval });

  // filter busyHours
  schedulingSlots = schedulingSlots.filter(slot => !isBranchBusyAtSlot(slot));

  const dateTimeAfterEstimatedTime = DateTime.now().plus({ minutes: defaultEstimatedTime || 0 });
  schedulingSlots = schedulingSlots.filter(slot => slot.isAfter(dateTimeAfterEstimatedTime));
  schedulingSlots = schedulingSlots.filter(slot => slot.isBefore(maxDateTimeToScheduleOrder));

  const formattedSchedulingSlotsOfTheDay = schedulingSlots.map(slot => {
    const fromTextEn = slot.start.toLocaleString({ hour: '2-digit', minute: '2-digit' });
    const toTextEn = slot.end.toLocaleString({ hour: '2-digit', minute: '2-digit' });
    const fromTextAr = configureToArDate(slot.start).toLocaleString({ hour: '2-digit', minute: '2-digit' });
    const toTextAr = configureToArDate(slot.end).toLocaleString({ hour: '2-digit', minute: '2-digit' });
    return {
      text: {
        en: `${fromTextEn} - ${toTextEn}`,
        ar: `${fromTextAr} - ${toTextAr}`,
      },
      interval: slot,
    };
  });

  const todaySchedulingSlots = formattedSchedulingSlotsOfTheDay.filter(({ interval }) =>
    interval.start.hasSame(dateTime, 'day'),
  );

  const remainingSlotsForNextDay = formattedSchedulingSlotsOfTheDay.filter(
    ({ interval }) => !interval.start.hasSame(dateTime, 'day'),
  );

  return {
    todaySchedulingSlots,
    remainingSlotsForNextDay,
  };
};

export const getSchedulingData = (schedulingTimes: SchedulingTimesType, fulfillmentTimeAndType) => {
  const scheduleDay = schedulingTimes.find(
    schedulingTime => schedulingTime.dateText?.en === fulfillmentTimeAndType.scheduleDateText?.en,
  );

  const scheduleSlot = scheduleDay?.schedulingSlots?.find(
    slot => slot.text?.en === fulfillmentTimeAndType.scheduleSlotTimeText?.en,
  );

  const scheduleDayAndSlotText =
    scheduleDay && scheduleSlot
      ? {
          en: `${scheduleDay.dayLongName?.en} ${scheduleSlot.text?.en}`,
          ar: `${scheduleDay.dayLongName?.ar} ${scheduleSlot.text?.ar}`,
        }
      : null;

  return { scheduleDay, scheduleSlot, scheduleDayAndSlotText };
};

export const storeIsBusyOrClosed = (reason, openingDateTime, isCartAvailable) => ({
  isASAPAvailable: false,
  isScheduledEnabled: false,
  isLoading: false,
  reason,
  schedulingTimes: null,
  isCartAvailable,
});

export const getFulfillmentTimeType = (scheduleDay, scheduleSlot, isASAPAvailable, persistedFulfillmentTimeAndType) => {
  if (
    scheduleDay &&
    scheduleSlot &&
    (!persistedFulfillmentTimeAndType || persistedFulfillmentTimeAndType?.fulfillmentType === ORDER_TIME_TYPES.SCHEDULE)
  )
    return ORDER_TIME_TYPES.SCHEDULE;
  if (
    isASAPAvailable &&
    (!persistedFulfillmentTimeAndType || persistedFulfillmentTimeAndType?.fulfillmentType === ORDER_TIME_TYPES.ASAP)
  )
    return ORDER_TIME_TYPES.ASAP;

  return null;
};

export const configureToArDate = date => date.reconfigure({ locale: 'ar', numberingSystem: 'arab' });

export const handleDurationHumanization = (text, shouldShortenDuration, durationObj) => ({
  en: `${text.en} ${Duration.fromObject(durationObj).toHuman({
    listStyle: shouldShortenDuration ? 'short' : 'long',
    ...(shouldShortenDuration && { unitDisplay: 'short' }),
  })}`,
  ar: `${text.ar} ${configureToArDate(Duration.fromObject(durationObj)).toHuman({
    listStyle: 'long',
  })}`,
});

export const getDurationTimeText: getDurationTimeTextType = (text, asapTimeInMinutes = 0, shouldShortenDuration) => {
  const dur = Duration.fromMillis(asapTimeInMinutes * 60000)
    .shiftTo('days', 'hours', 'minutes')
    .toObject();

  if (dur.days > 0) {
    return handleDurationHumanization(text, shouldShortenDuration, {
      ...(dur.days > 1 && dur.hours > 0 ? { days: dur.days + 1 } : { days: dur.days }),
      ...(dur.days === 1 && dur.hours > 0 && { hours: dur.hours }),
    });
  }
  if (dur.hours > 0) {
    return handleDurationHumanization(text, shouldShortenDuration, {
      hours: dur.hours,
      ...(dur.minutes > 0 && { minutes: dur.minutes }),
    });
  }
  return handleDurationHumanization(text, shouldShortenDuration, { minutes: dur.minutes });
};

// TODO: use translation function instead of these keys but we have to save arabic and english together!
export const getEstimatedTimeText = (fulfillmentMode, openingDateTime) => {
  const isPickup = fulfillmentMode === FULFILLMENT_MODES.PICKUP || fulfillmentMode === FULFILLMENT_MODES.CAR_PICKUP;
  const modeText = isPickup ? { en: 'Pickup', ar: 'الاستلام' } : { en: 'Delivery', ar: 'التوصيل' };

  if (!openingDateTime) {
    return {
      en: `${modeText.en} is not available now`,
      ar: `${modeText.ar} غير متاح حاليا`,
    };
  }
  return {
    en: `${openingDateTime.toLocaleString(DateTime.TIME_SIMPLE)}, ${getWeekDayLongName(openingDateTime)}`,
    ar: `${configureToArDate(openingDateTime).toLocaleString(DateTime.TIME_SIMPLE)}، ${getWeekDayLongName(
      configureToArDate(openingDateTime),
    )}`,
  };
};

export const getMaxSchedulingDays = scheduleMaxTimePeriod => {
  const HOURS_IN_DAY = 24;
  const maxSchedulingDaysNumber = scheduleMaxTimePeriod / HOURS_IN_DAY + (scheduleMaxTimePeriod % HOURS_IN_DAY ? 1 : 0);
  return maxSchedulingDaysNumber;
};

export const getNextAvailableWorkingDayInterval = (workingHoursByDay, schedulingWeekDaysNumber) => {
  const datTimeNow = DateTime.now();
  for (let i = 0; i < schedulingWeekDaysNumber; i++) {
    const dayDateTime = datTimeNow.plus({ days: i }).setLocale('en');
    const dayWorkingHours = workingHoursByDay[dayDateTime.weekdayShort.toLowerCase()];
    if (!dayWorkingHours) {
      // TODO: remove this until we know the issue
      logger.error({
        error: 'workingHours error',
        extraData: { workingHoursByDay, schedulingWeekDaysNumber, todayName: dayDateTime?.weekdayShort },
      });
    }
    const dayInterval =
      dayWorkingHours &&
      !dayWorkingHours.offDay &&
      getFullDaySchedulingInterval(dayDateTime, dayWorkingHours.openAt, dayWorkingHours.closeAt);
    if (dayInterval && dayInterval.isAfter(datTimeNow)) return dayInterval;
  }
  return null;
};

export const checkCalculatedOrderTimeInit = (orderTime: EstimatedTimeType | null): boolean => {
  if (!orderTime || orderTime.isLoading) return false;

  // Base required fields
  const hasRequiredFields =
    orderTime.fulfillmentTimeType !== undefined &&
    orderTime.isASAPAvailable !== undefined &&
    orderTime.isScheduledEnabled !== undefined &&
    orderTime.estimatedTimeText !== undefined;
  return hasRequiredFields;
};
